
window.SIRI_INTENTS = {"PLAY_MY_CAPSULE": "com.trebble.playCapsuleFeed"};
window.SiriIntentHelper = {};
window.SiriIntentHelper.createDonationOptions  = function(intentId, event, params, title, suggestedInvocationPhrase ){
   var options = {};
   options.persistentIdentifier  = intentId;
   options.title  = title;
   options.suggestedInvocationPhrase  = suggestedInvocationPhrase;
   options.userInfo   = {"event": event,"params": params};
   options.isEligibleForSearch = true;
   options.isEligibleForPrediction = true;
   return options;
}



window.SiriIntentHelper.createDonationOptionsForPlayCapsuleFeedIntent  = function(event, params ){
    var PLAY_MY_NEWS_PER_LANGUAGE = {"en": "Play My News", "fr": "Joues mes nouvelles"};

    var playMyNewsLocalized =  PLAY_MY_NEWS_PER_LANGUAGE[window.getAppUsedLanguage()]
    return window.SiriIntentHelper.createDonationOptions(window.SIRI_INTENTS.PLAY_MY_CAPSULE, event, params, playMyNewsLocalized, playMyNewsLocalized)
}


var scriptSiriShortcut = {

    execute: function() {
        this.bindEvents();
    },

    
    
    bindEvents: function() {
        document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);




    },

    onDeviceReady: function() {

        document.addEventListener('playingCapsuleFeed', this.onPlayingCapsuleFeed, false);
        document.addEventListener("resume", this.onApplicationInForeground, false);
        this.onApplicationInForeground();
    },

    onPlayingCapsuleFeed : function(event){
        var isIOSMobileDevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if('cordova' in window && cordova && cordova.plugins && cordova.plugins.SiriShortcuts && isIOSMobileDevice){
            var userId = event.detail;
            var options = window.SiriIntentHelper.createDonationOptionsForPlayCapsuleFeedIntent("playingCapsuleFeed", {"userId": userId});
            var onDonationSuccess = function(){

            }
            var onDonationFailure = function(){

            }
            cordova.plugins.SiriShortcuts.donate(options, onDonationSuccess, onDonationFailure );

        }
    },

    inviteUserToAddAShortcutToPlayCapsuleFeed : function(userId, successFunc, errorFunc){
        var isIOSMobileDevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if('cordova' in window && cordova && cordova.plugins && cordova.plugins.SiriShortcuts && isIOSMobileDevice){
            var options = window.SiriIntentHelper.createDonationOptionsForPlayCapsuleFeedIntent("playingCapsuleFeed", {"userId": userId});
            cordova.plugins.SiriShortcuts.present(options, successFunc, errorFunc );

        }
    },

    onApplicationInForeground : function(){
        var isIOSMobileDevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        if('cordova' in window && cordova && cordova.plugins && cordova.plugins.SiriShortcuts && isIOSMobileDevice){
            var onSuccess = function(options){
                var getAppBaseUrl  = function(){
                    if(window.location.pathname.indexOf(".html") != -1){
                        return window.location.pathname.substr(0,window.location.pathname.indexOf(".html")) + ".html" +"/";
                    }else{
                        return "/";
                    }
                }   
                var baseUrlWithSlash = location.origin + getAppBaseUrl();
                var baseUrlWithoutSlash = baseUrlWithSlash.charAt(baseUrlWithSlash.length -1) == "/"? baseUrlWithSlash.substr(0,baseUrlWithSlash.length -1 ):baseUrlWithSlash;
                if(options &&  options.userInfo && options.userInfo.event == "playingCapsuleFeed"  &&  options.userInfo.params &&  options.userInfo.params.userId){
                    window.location.assign(baseUrlWithoutSlash + "#" +"playCapsuleFeed"+"/"+options.userInfo.params.userId);
                    if(window.trebbleAnalyticsHelper){
                        window.trebbleAnalyticsHelper.trackEvent("SiriShortcuts", 'CapsuleFeedPlaybackInitiatedViaSiriShortcut', 'Capsule Feed Playback Was Initiatied Via Siri Shortcut');
                    }
                }
            }
            var onFailure = function(error){
                console.error(error);
            }
            cordova.plugins.SiriShortcuts.getActivatedShortcut({clear: true}, onSuccess, onFailure );

        }
    },

    setupListenerForPlayingCapsuleFeedEvent : function(){
        document.addEventListener('deviceready', this.onDeviceReady, false);
    },


};

window.SiriIntentHelper.inviteUserToAddAShortcutToPlayCapsuleFeed = scriptSiriShortcut.inviteUserToAddAShortcutToPlayCapsuleFeed.bind(scriptSiriShortcut);

scriptSiriShortcut.execute();